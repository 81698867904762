if ((document.getElementsByTagName('body')[ 0 ].classList.contains('home') || document.getElementsByTagName('body')[ 0 ].classList.contains('page-template-page-frontpage') || document.getElementsByTagName('body')[ 0 ].classList.contains('page-template-page-landingpage')) && document.querySelector('#crash')) {
	document.querySelector('#crash').addEventListener('animationend', (_sel) => {
		document.querySelector('#scene').classList.add('shake');
		tiltLogo();
	});
	function tiltLogo() {
		document.querySelector('.shake').addEventListener('animationend', (_sel) => {
			document.querySelector('#crash').classList.add('tilt');
			document.querySelector('.shake').classList.add('shaken');
		});
	}

	window.addEventListener('scroll', () => {
		if (document.querySelector('#moon')) {
			document.querySelector('#moon').style.transform = 'translateY(' + (window.pageYOffset * 0.2) * -1 + 'px)';
		}
	});

	if (document.querySelector('.offset-y')) {
		const offset = document.querySelector('.offset-y').getBoundingClientRect().height - 5;
		document.documentElement.style.setProperty('--offset', ''.concat(offset, 'px'));
	}
}

if (document.querySelector('#toggle-nav')) {
	document.querySelector('#toggle-nav').addEventListener('click', () => {
		document.querySelector('body').classList.toggle('nav-open');
	});
}

function formInput() {
	// Get the file upload field element
	const fileUploadField = document.getElementById('input_1_5');

	// Get the hidden input field element
	const hiddenInputField = document.getElementById('input_1_6');

	// Add an event listener to the file upload field
	fileUploadField.addEventListener('change', function(event) {
		// Get the selected file
		const file = event.target.files[ 0 ];

		// Create a file reader
		const reader = new FileReader();

		// Set up the file reader onload event
		reader.onload = function(e) {
			// Get the URL of the uploaded file
			const fileUrl = e.target.result;

			// Set the value of the hidden input field to the file URL
			hiddenInputField.value = fileUrl;
		};

		// Read the selected file as a Data URL
		reader.readAsDataURL(file);
	});
}

if (document.querySelector('.gform_wrapper') && document.getElementById('input_1_5')) {
	formInput();
}

setTimeout(function() {
	let id = 'site-main';
	if (document.getElementsByTagName('body')[ 0 ].classList.contains('home')) {
		id = 'site-main';
	} else if (document.getElementsByTagName('body')[ 0 ].classList.contains('page-template-page-ticket-calendar') ||
	document.getElementsByTagName('body')[ 0 ].classList.contains('page-template-page-content')
	) {
		id = 'booking-masthead';
	} else {
		id = 'masthead';
	}

	particlesJS(id, { 'particles': { 'number': { 'value': 100, 'density': { 'enable': true, 'value_area': 800 } }, 'color': { 'value': '#fff' }, 'shape': { 'type': 'circle', 'stroke': { 'width': 0, 'color': '#000000' }, 'polygon': { 'nb_sides': 5 }, 'image': { 'src': 'img/github.svg', 'width': 100, 'height': 100 } }, 'opacity': { 'value': 0.5, 'random': true, 'anim': { 'enable': false, 'speed': 1, 'opacity_min': 0.1, 'sync': false } }, 'size': { 'value': 6, 'random': true, 'anim': { 'enable': false, 'speed': 40, 'size_min': 0.1, 'sync': false } }, 'line_linked': { 'enable': false, 'distance': 500, 'color': '#ffffff', 'opacity': 0.4, 'width': 2 }, 'move': { 'enable': true, 'speed': 3, 'direction': 'bottom', 'random': false, 'straight': false, 'out_mode': 'out', 'bounce': false, 'attract': { 'enable': false, 'rotateX': 600, 'rotateY': 1200 } } }, 'interactivity': { 'detect_on': 'canvas', 'events': { 'onhover': { 'enable': false, 'mode': 'bubble' }, 'onclick': { 'enable': false, 'mode': 'repulse' }, 'resize': true }, 'modes': { 'grab': { 'distance': 400, 'line_linked': { 'opacity': 0.5 } }, 'bubble': { 'distance': 400, 'size': 4, 'duration': 0.3, 'opacity': 1, 'speed': 3 }, 'repulse': { 'distance': 200, 'duration': 0.4 }, 'push': { 'particles_nb': 4 }, 'remove': { 'particles_nb': 2 } } }, 'retina_detect': true });
}, 100);

const resizeMap = function() {
	if (document.querySelector('#map')) {
		const iframe = document.querySelector('#map');
		iframe.height = (document.querySelector('#map').getBoundingClientRect().width * 0.714);
	}
};
window.addEventListener('resize', resizeMap);
resizeMap();

(function($) {
	$(document).ready(function() {
		if ($('.home').find('.wp-block-embed__wrapper').length > 0) {
			const $iframe = $('.home').find('.wp-block-embed__wrapper').find('iframe');
			let src = $iframe[ 0 ].src;
			let originalSrc;
			src = src + '&enablejsapi=1';

			originalSrc = src;

			$iframe.attr('src', src);

			$iframe.closest('.wp-block-embed__wrapper').on('click', function(e) {
				$(this).toggleClass('iframe--playing');

				if ($(this).hasClass('iframe--playing')) {
					src = src + '&autoplay=1&playsinline=1';
				} else {
					src = originalSrc;
				}

				$iframe.attr('src', src);
			});
		}

		if ($('.quotes-slider').length > 0 || $('.home').find('.reviews').length > 0) {
			const $homeReviews = $('.home').find('.reviews');
			let $container;

			if ($homeReviews.length > 0) {
				$container = '.' + $homeReviews[ 0 ].className;
			} else {
				$container = '.quotes-slider .wp-block-group__inner-container';
			}

			const config = {
				container: $container,
				items: 1,
				slideBy: 'page',
				autoplay: true,
				loop: true,
				mouseDrag: true,
				controls: false,
				nav: (typeof $homeReviews !== 'undefined' && $homeReviews.length > 0) ? false : true,
				navPosition: 'bottom',
				navAsThumbnails: false,
				autoplayButtonOutput: false
			};
			let slider;

			slider = window.vendors.tns(config);
		}

		if ($('#calendar').length > 0) {
			const cal = $('#calendar').fullCalendar({
				eventSources: [
					{
						events: calendar.instances.sold_out,
						color: '#fff',
						textColor: '#fff'
					},
					{
						events: calendar.instances.available,
						color: '#fff',
						textColor: '#fff'
					}
				],
				handleWindowResize: true,
				defaultDate: moment(calendar.instances.available[ 0 ].start),
				firstDay: 1,
				timeFormat: 'h:mma',

				visibleRange: {
					start: moment(calendar.instances.range[ 0 ]).startOf('month').format('YYYY-MM-DD'),
					end: moment(calendar.instances.range[ 1 ]).endOf('month').format('YYYY-MM-DD')
				},
				validRange: {
					start: moment(calendar.instances.range[ 0 ]).startOf('month').format('YYYY-MM-DD'),
					end: moment(calendar.instances.range[ 1 ]).endOf('month').format('YYYY-MM-DD')
				},
				eventRender(event, element) {
					if (event.available < 100) {
						element.addClass('limited-availability');
					} else if (event.available >= 800) {
						element.addClass('good-availability');
					} else if (event.available < 1) {
						element.addClass('disabled');
					}
				}
			});
			const switchView = function() {
				if (window.innerWidth <= 650) {
					cal.fullCalendar('changeView', 'listMonth');
				} else {
					cal.fullCalendar('changeView', 'month');
				}
			};
			$(window).resize(function() {
				switchView();
			}); switchView();
		}
	});

	if (typeof navigator !== 'undefined') {
		navigator.serviceWorker.getRegistrations().then(function(registrations) {
			for (const registration of registrations) {
				registration.unregister();
			}
		});
	}
}(jQuery));
